<template>
  <div>
    <div class="user-container">
      <div class="w-50" v-if="allows_crud">
        <NewGenericBTable
          :display_id="'NewGenericBTable' + key_component"
          :key="generic_table_key"
          :search_filter="true"
          :columns_display="true"
          :fields="fields"
          :selection_mode="allows_crud"
          :select_mode="'range'"
          :pre_filter="pre_filter"
          :pre_multiple_search="pre_multiple_search"
          @selected="slotSelectedLeft"
          :url="'/authentication/alt-user/'"
        >
          <template #cell(first_name)="row">
            {{ row.item.first_name }} {{ row.item.last_name }}
          </template>
        </NewGenericBTable>
      </div>
      <div class="align-self-center">
        <div class="buttons-container">
          <b-button
            v-if="allows_crud"
            variant="info"
            @click="moveToSelected"
            size="sm"
            class="mb-2"
          >
            <b-icon-arrow-right></b-icon-arrow-right>
          </b-button>
          <b-button
            v-if="allows_crud"
            variant="info"
            @click="moveToUnSelected"
            size="sm"
          >
            <b-icon-arrow-left></b-icon-arrow-left>
          </b-button>
        </div>
      </div>
      <div :class="{ 'w-100': !allows_crud }" class="w-50">
        <GenericBTable
          :display_id="'GenericBTable_' + key_component"
          :items="users_container_right"
          :fields="fields2"
          :columns_display="true"
          :selection_mode="allows_crud"
          :show_select_mode="allows_crud"
          :show_pagination="true"
          :pagination="10"
          :select_mode="'range'"
          @selected="slotSelectedRight"
        >
          <template #cell(first_name)="row">
            {{ row.item.first_name }} {{ row.item.last_name }}
          </template>
        </GenericBTable>
      </div>
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "NewUserList",
  components: {
    NewGenericBTable: () => import("@/components/reusable/NewGenericBTable"),
    GenericBTable: () => import("@/components/reusable/GenericBTable"),
  },
  props: {
    key_component: {
      type: String,
    },
    users_ids: {
      type: Array,
    },
    pre_multiple_search: {
      type: Array,
      default() {
        return [];
      },
    },
    allows_crud: {
      type: Boolean,
      default: true,
    },
    // ["id_exclude_in=1","groups__id__in=7","groups__id__exclude__in=1"]
    pre_filter_array: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      generic_table_key: 0,
      users_container_right: [],
      left_selected_user: [],
      right_selected_user: [],
    };
  },
  computed: {
    ...mapGetters({
      roles: names.ROLES,
    }),
    pre_filter() {
      let partial_url = [];
      if (this.users_container_right.length > 0)
        partial_url.push(
          "id__exclude__in=" +
            this.users_container_right.map((x) => x.id).join(",")
        );
      if (this.pre_filter_array.length > 0)
        partial_url = partial_url.concat(this.pre_filter_array);
      return partial_url.join("&");
    },
    fields() {
      return [
        {
          key: "first_name",
          label: "Usuario",
          sortable: true,
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
        },
        {
          key: "user_data",
          label: "Identificador",
          sortable: true,
          display_column: false,
          thStyle: { background: "var(--primary-color) !important" },
          formatter: (value) => {
            return value ? value.identifier : "";
          },
        },
        {
          key: "email",
          label: "Email",
          sortable: true,
          display_column: false,
          thStyle: { background: "var(--primary-color) !important" },
        },
        {
          key: "role",
          label: "Rol",
          sortable: true,
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
          formatter: (value) => {
            const rol = this.roles.find((x) => x.id == value);
            return rol ? rol.position_name : "";
          },
        },
      ];
    },
    fields2() {
      return [
        {
          key: "first_name",
          label: "Usuario",
          sortable: true,
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
        },
        {
          key: "user_data",
          label: "Identificador",
          sortable: true,
          display_column: false,
          thStyle: { background: "var(--primary-color) !important" },
          formatter: (value) => {
            return value ? value.identifier : "";
          },
        },
        {
          key: "email",
          label: "Email",
          sortable: true,
          display_column: false,
          thStyle: { background: "var(--primary-color) !important" },
        },
        {
          key: "role",
          label: "Rol",
          sortable: true,
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
          formatter: (value) => {
            const rol = this.roles.find((x) => x.id == value);
            return rol ? rol.position_name : "";
          },
        },
      ];
    },
  },
  methods: {
    slotSelectedLeft(rows) {
      this.left_selected_user = rows;
    },
    slotSelectedRight(rows) {
      this.right_selected_user = rows;
    },
    moveToSelected() {
      const left_selected_user = this.left_selected_user;
      left_selected_user.forEach((user, index) => {
        if (!this.users_container_right.find((x) => x.id == user.id))
          this.users_container_right.push(user);
        if (left_selected_user.length == index + 1) {
          this.left_selected_user = [];
          this.generic_table_key++;
          this.$emit("selected_user", this.users_container_right);
        }
      });
    },
    moveToUnSelected() {
      const right_selected_user = this.right_selected_user;
      right_selected_user.forEach((user, index) => {
        const index_user = this.users_container_right.findIndex(
          (x) => x.id == user.id
        );
        if (index_user != -1) this.users_container_right.splice(index_user, 1);
        if (right_selected_user.length == index + 1) {
          this.right_selected_user = [];
          this.generic_table_key++;
          this.$emit("selected_user", this.users_container_right);
        }
      });
    },
  },
  created() {
    if (this.users_ids.length > 0) {
      this.$restful
        .Get(`/authentication/alt-user/?id__in=${this.users_ids.join(",")}`)
        .then((response) => {
          this.users_container_right = response.results;
          this.generic_table_key++;
        });
    }
  },
};
</script>
<style scoped>
.buttons-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}
.user-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
</style>

